import isEmpty from "helpers/isEmpty";
import {
  APPLICANT_NAME_COLUMN,
  DATE_CREATED_COLUMN,
  APPLICATION_TOTAL_COLUMN,
  OWNER_COLUMN,
  INTRODUCER_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
} from "utils/constants";

export const defineApplicationColumns = (items: any[]) => {
  let columns: Array<string | null> = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  items.forEach((item) => {
    if (!isEmpty(item.account) && !isEmpty(item.account.name)) {
      columns[0] = APPLICANT_NAME_COLUMN;
    }
    if (!isEmpty(item.created)) {
      columns[1] = DATE_CREATED_COLUMN;
    }
    if (!isEmpty(item.facilityLimit)) {
      columns[3] = APPLICATION_TOTAL_COLUMN;
    }
    if (!isEmpty(item.contact) && !isEmpty(item.contact.name)) {
      columns[4] = OWNER_COLUMN;
    }
    if (
      !isEmpty(item.introducerContact) &&
      !isEmpty(item.introducerContact.name)
    ) {
      columns[5] = INTRODUCER_COLUMN;
    }
    if (!isEmpty(item.category)) {
      columns[6] = CATEGORY_COLUMN;
    }
    if (!isEmpty(item.stepName)) {
      columns[7] = STATUS_COLUMN;
    }
  });

  return columns.filter((col) => col !== null);
};

import { FastDocError } from "./../helpers/data-helpers/getFastDocErrorStateFromResponse";
import { FastDocRecommendation } from "./../helpers/data-helpers/getFastDocRecommendationStateFromResponse";
import {
  ADD_NEW_CUSTOMER_FAILURE,
  ADD_NEW_CUSTOMER_REQUEST,
  ADD_NEW_CUSTOMER_SUCCESS,
  AF_GET_BROKER_FAILURE,
  AF_GET_BROKER_REQUEST,
  AF_GET_BROKER_SUCCESS,
  AF_SELECT_BROKER,
  CLEAR_ASSET_FINANCE_ERROR,
  CLEAR_ASSET_FINANCE_RECOMMENDATION,
  CREATE_APPLICATION_FAILURE,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  FINANCING_NEEDS_FAILURE,
  FINANCING_NEEDS_REQUEST,
  FINANCING_NEEDS_SUCCESS,
  GET_AF_APPLICATION_FAILURE,
  GET_AF_APPLICATION_REQUEST,
  GET_AF_APPLICATION_SUCCESS,
  GET_AF_FILES_FAILURE,
  GET_AF_FILES_REQUEST,
  GET_AF_FILES_SUCCESS,
  GET_ALL_AFAPPLICATIONS_FAILURE,
  GET_ALL_AFAPPLICATIONS_REQUEST,
  GET_ALL_AFAPPLICATIONS_SUCCESS,
  GET_ALL_AFCRITERIA_FAILURE,
  GET_ALL_AFCRITERIA_REQUEST,
  GET_ALL_AFCRITERIA_SUCCESS,
  GET_CUSTOMER_DETAILS_FAILURE,
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  GET_SUMMARY_AFCRITERIA_FAILURE,
  GET_SUMMARY_AFCRITERIA_REQUEST,
  GET_SUMMARY_AFCRITERIA_SUCCESS,
  REMOVE_ITEM_AT_PATH,
  RESET_ASSET_FINANCE_STATE,
  SET_CUSTOMER_DETAILS,
  SET_ITEM_AT_PATH,
  SUBMIT_APPLICATION_FAILURE,
  SUBMIT_APPLICATION_REQUEST,
  SUBMIT_APPLICATION_SUCCESS,
  UPDATE_ALL_AFCRITERIA_FAILURE,
  UPDATE_ALL_AFCRITERIA_REQUEST,
  UPDATE_ALL_AFCRITERIA_SUCCESS,
  UPDATE_APPLICATION_FAILURE,
  UPDATE_APPLICATION_INFORMATION_FAILURE,
  UPDATE_APPLICATION_INFORMATION_REQUEST,
  UPDATE_APPLICATION_INFORMATION_SUCCESS,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_GUARANTOR_INFO_FAILURE,
  UPDATE_GUARANTOR_INFO_REQUEST,
  UPDATE_GUARANTOR_INFO_SUCCESS,
  UPDATE_OFFICE_HOLDERS_FAILURE,
  UPDATE_OFFICE_HOLDERS_REQUEST,
  UPDATE_OFFICE_HOLDERS_SUCCESS,
  UPDATE_SELECTED_BUSINESS_STRUCTURE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPDATE_BROKER_NOB_SUCCESS,
} from "actions/types";
import { logAssetFinanceComplete, logAssetFinanceStart } from "config/firebase";
import { defineAfApplicationColumns } from "helpers/data-helpers";
import { getFastDocErrorStateFromResponse } from "helpers/data-helpers/getFastDocErrorStateFromResponse";
import { getFastDocRecommendationStateFromResponse } from "helpers/data-helpers/getFastDocRecommendationStateFromResponse";
import {
  removeItemAtPathRedux,
  setItemAtPathRedux,
} from "helpers/data-helpers/itemAtPath";
import {
  mapApiErrorToReduxState,
  mapApiToAfFinanceNeeds,
  mapApiToReduxCustomerDetails,
  mapBackgroundResponse,
  mapGuarantorsResponse,
  Merge,
} from "helpers/mappers";
import { loadingState, unloadingState } from "helpers/reducerHelpers";
import cloneDeep from "lodash/cloneDeep";
import { AssetFinance } from "redux-models/reduxStateTypes";
import { HttpErrorAction } from "utils/api";
import { allAFApplicationColumns } from "utils/constants";

const initialStructureValues = {
  company: {},
  sole: {},
  foreign: {},
  partnership: {},
  gov: {},
  coop: {},
  assoc: {},
  trust: {},
};

const initialState: AssetFinance = {
  fastDocErrors: [],
  fastDocRecommendations: [],
  pendingApplications: [],
  columns: allAFApplicationColumns,
  contractId: null,
  afApplicationId: null,
  error: null,
  recommendation: null,
  loading: false,
  isSubmitted: false,
  submittedDate: null,
  brokerInfo: null,
  favoriteQuoteId: null,
  currentUploadedFile: null,
  pageNumber: null,
  pageSize: null,
  totalPages: null,

  AFCriteria: null,
  AFSummary: null,
  informationSave: undefined,

  form: {
    broker: null,
    customerName: undefined,
    authorizationCheck: false,
    structure: null,
    selectedCustomerId: null,
    documents: [],
    financeNeeds: undefined,
    guarantors: [],
    afBackground: undefined,
    ...initialStructureValues,
  },
};

const assetFinance = (
  state: AssetFinance = initialState,
  action: { type: string; payload: any } | HttpErrorAction
): AssetFinance => {
  switch (action.type) {
    case RESET_ASSET_FINANCE_STATE:
      return {
        ...initialState,
        loading: state.loading,
        loadingActions: state.loadingActions,
      };

    case AF_SELECT_BROKER:
      const newState = cloneDeep(initialState);
      newState.form.broker = action.payload;
      return newState;

    case SET_ITEM_AT_PATH:
      return setItemAtPathRedux(state, action);

    case REMOVE_ITEM_AT_PATH:
      return removeItemAtPathRedux(state, action);

    case SET_CUSTOMER_DETAILS:
      if (!state.form.structure) {
        return unloadingState(state, action.type);
      }
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...action.payload,
        },
      };

    case UPDATE_SELECTED_BUSINESS_STRUCTURE:
      return {
        ...state,
        form: {
          ...state.form,
          ...initialStructureValues,
          structure: action.payload,
        },
      };

    case CLEAR_ASSET_FINANCE_ERROR:
      return {
        ...state,
        fastDocErrors: initialState.fastDocErrors,
      };

    case CLEAR_ASSET_FINANCE_RECOMMENDATION:
      return {
        ...state,
        fastDocRecommendations: initialState.fastDocRecommendations,
      };

    case GET_AF_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case GET_AF_APPLICATION_SUCCESS: {
      const res = action.payload;
      return {
        ...unloadingState(state, action.type),
        afApplicationId: res.id,
        isSubmitted: res.isSubmitted,
        submittedDate: res.submitted,
        brokerInfo: res.brokerInfo,
        form: {
          ...state.form,
          selectedCustomerId: res.customerId,
          afBackground: mapBackgroundResponse(res.afBackground),
          financeNeeds: mapApiToAfFinanceNeeds(res),
        },
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    }
    case GET_AF_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_AF_FILES_REQUEST:
      return loadingState(state, action.type);
    case GET_AF_FILES_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          documents: action.payload.documents,
        },
      };
    case GET_AF_FILES_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPLOAD_FILE_REQUEST:
      return loadingState(state, action.type);
    case UPLOAD_FILE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          documents: [action.payload, ...state.form.documents],
        },
      };
    case UPLOAD_FILE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case ADD_NEW_CUSTOMER_REQUEST:
      return loadingState(state, action.type);
    case ADD_NEW_CUSTOMER_SUCCESS:
      const mappedResponse = mapApiToReduxCustomerDetails({
        resp: action.payload,
        state: state.form,
        strategy: Merge.All,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    case ADD_NEW_CUSTOMER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_CUSTOMER_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_CUSTOMER_SUCCESS: {
      const mappedResponse = mapApiToReduxCustomerDetails({
        resp: action.payload,
        state: state.form,
        strategy: Merge.Business,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case UPDATE_CUSTOMER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_CUSTOMER_DETAILS_REQUEST:
      return loadingState(state, action.type);
    case GET_CUSTOMER_DETAILS_SUCCESS: {
      const mappedResponse = mapApiToReduxCustomerDetails({
        resp: action.payload,
        state: state.form,
        strategy: Merge.All,
      });

      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case GET_CUSTOMER_DETAILS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_OFFICE_HOLDERS_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_OFFICE_HOLDERS_SUCCESS: {
      const mappedResponse = mapApiToReduxCustomerDetails({
        resp: action.payload,
        state: state.form,
        strategy: Merge.Clients,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case UPDATE_OFFICE_HOLDERS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_ALL_AFAPPLICATIONS_REQUEST:
      return loadingState(state, action.type);
    case GET_ALL_AFAPPLICATIONS_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        pendingApplications: action.payload.items,
        columns: defineAfApplicationColumns(action.payload.items),
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
      };
    case GET_ALL_AFAPPLICATIONS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CREATE_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case CREATE_APPLICATION_SUCCESS:
      logAssetFinanceStart(action.payload.isFullDoc);
      return {
        ...unloadingState(state, action.type),
        afApplicationId: action.payload.id,
        form: {
          ...state.form,
          selectedCustomerId: action.payload.customerId,
        },
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case CREATE_APPLICATION_FAILURE:
      return mapApiErrorAndRecommendationToReduxState(action, action, state);

    case UPDATE_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_APPLICATION_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        afApplicationId: action.payload.id,
        form: {
          ...state.form,
          selectedCustomerId: action.payload.customerId,
        },
        ...getFastDocErrorStateFromResponse(action),
      };
    case UPDATE_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case FINANCING_NEEDS_REQUEST:
      return loadingState(state, action.type);
    case FINANCING_NEEDS_SUCCESS: {
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          financeNeeds: mapApiToAfFinanceNeeds(action.payload),
        },
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    }
    case FINANCING_NEEDS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_APPLICATION_INFORMATION_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_APPLICATION_INFORMATION_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          afBackground: mapBackgroundResponse(action.payload.afBackground),
        },
      };
    case UPDATE_APPLICATION_INFORMATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case AF_GET_BROKER_REQUEST:
      return loadingState(state, action.type);
    case AF_GET_BROKER_SUCCESS:
      const user = action.payload;
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          broker: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            companyName: user.company?.name,
            address: user.company?.address,
            role: user.role,
            status: user.status,
          },
        },
      };
    case AF_GET_BROKER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case SUBMIT_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case SUBMIT_APPLICATION_SUCCESS:
      const res = action.payload;
      logAssetFinanceComplete(res.isFullDoc);
      return {
        ...unloadingState(state, action.type),
        isSubmitted: true,
        submittedDate: Date(),
        brokerInfo: res.brokerInfo,
        loading: false,
      };
    case SUBMIT_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_SUMMARY_AFCRITERIA_REQUEST:
      return loadingState(state, action.type);
    case GET_SUMMARY_AFCRITERIA_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        AFSummary: action.payload,
      };
    case GET_SUMMARY_AFCRITERIA_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_ALL_AFCRITERIA_REQUEST:
      return loadingState(state, action.type);
    case GET_ALL_AFCRITERIA_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        AFCriteria: action.payload,
      };
    case GET_ALL_AFCRITERIA_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_GUARANTOR_INFO_REQUEST:
      return state;
    case UPDATE_GUARANTOR_INFO_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          guarantors: mapGuarantorsResponse(action.payload.guarantors),
        },
      };
    case UPDATE_GUARANTOR_INFO_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_ALL_AFCRITERIA_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_ALL_AFCRITERIA_SUCCESS:
      return { ...unloadingState(state, action.type) };
    case UPDATE_ALL_AFCRITERIA_FAILURE:
      return mapApiErrorToReduxState(action, state);
    case UPDATE_BROKER_NOB_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          financeNeeds: mapApiToAfFinanceNeeds(action.payload),
        },
      };

    default:
      return state;
  }
};
export default assetFinance;
function mapApiErrorAndRecommendationToReduxState(
  action: { type: string; payload: any } | HttpErrorAction,
  action1: { type: string; payload: any } | HttpErrorAction,
  state: AssetFinance
): AssetFinance {
  throw new Error("Function not implemented.");
}
